import { toXML } from 'jstoxml';
const ECP = {
    state: {
        webSocket:'ggg',
        callback:null,
        tokens:[],
        active_token:'',
        key:[],
        signed_xml:'',
        ecp_loading: false,
        ecp_error:'',
    },
    getters: {
        WEB_SOCKET: state => {
            return state.webSocket
          },
        CALLBACK: state =>{
            return state.callback
        },
        TOKENS: state =>{
            return state.tokens
        },
        ACTIVE_TOKEN: state =>{
            return state.active_token
        },
        KEY: state => {
            return state.key
        },
        SIGNED_XML: state =>{
            return state.signed_xml
        },
        ECP_LOADING: state =>{
            return state.ecp_loading
        },
        ECP_ERROR: state=>{
            return state.ecp_error
        }
        
    },
    mutations: {
        SET_SOCKET:(state,socket) =>{
            state.webSocket = socket
        },
        SET_CALLBACK:(state, callback) =>{
            state.callback = callback
        },
        SET_TOKENS:(state, tokens) => {
            state.tokens = tokens
        },
        SET_ACTIVE_TOKEN:(state, token) =>{
            state.active_token = token
        },
        SET_KEY:(state, key) =>{
            state.key = key
        
        },
        SET_SIGNED_XML:(state, data)=>{
            state.signed_xml = data
        },
        SET_ECP_LOADING:(state, status)=>{
            state.ecp_loading = status
        },
        SET_ECP_ERROR:(state, status)=>{
            state.ecp_error = status
        }
    },
    actions: {
        // Инициализация ЭЦП
        INIT_ECP: async (state, callback) =>{

            // Устанавливаем статус загрузки
            state.commit('SET_ECP_LOADING', true)
            return new Promise((resolve, reject) => {

            // Инициируем соединение с NCALayer
            var webSocket = new WebSocket('wss://127.0.0.1:13579/');
            state.commit('SET_SOCKET', webSocket)

            // Если соединение открыто успешно
            webSocket.onopen = function (event) {
                // Сбрасываем загрузку устанавливаем отсутствие ошибок
                state.commit('SET_ECP_LOADING', false)
                state.commit('SET_ECP_ERROR', false)
                resolve(webSocket)
                
            };
            
            // Если соединение закрыто
            webSocket.onclose = function (event) {
                // Сбрасываем загрузку устанавливаем наличие ошибок
                state.commit('SET_ECP_LOADING', false)
                state.commit('SET_ECP_ERROR', true)
            };
            
            //Если отправляется сообщение
            webSocket.onmessage = function (event) {
                // Получаем данные с NCALayer
                var result = JSON.parse(event.data);
                
                if (result != null) {
                    // Приводим к формату
                    var rw = {
                        code: result['code'],
                        message: result['message'],
                        responseObject: result['responseObject'],
                    };
                    // Устанавливаем функцию обработчик
                    if(state.getters.CALLBACK != null){
                        state.getters.CALLBACK(rw)
                    }
                    
                    
                }
            };

            // В случае ошибки
            webSocket.onerror = function(event){
                // Сбрасываем загрузку устанавливаем наличие ошибок
                reject(event)
                state.commit('SET_ECP_LOADING', false)
                state.commit('SET_ECP_ERROR', true)
            }
            
            })},
        // Получаем подключенные хранилища
        GET_ACTIVE_TOKENS: async (state) =>{
            var getActiveTokens = {
                    "module": "kz.gov.pki.knca.commonUtils",
                    "method": "getActiveTokens"
                };
            var callback = function(result){
                var res_arr = []
                // В случае успеха добавляем Файловую систему и сохраняем список
                if(result.code == 200){
                    res_arr.push({name:'Файловая система', value:'PKCS12'})
                    if(result.responseObject.length >0){
                        result.responseObject.forEach(element => {
                            res_arr.push({name:element, value:element})
                        });
                    }
                    state.commit('SET_TOKENS', res_arr)
                    state.commit('SET_ACTIVE_TOKEN', res_arr[0]['value'])
                }else{
                    console.log(result)
                }
            } 
            state.commit('SET_CALLBACK', callback)     
            state.getters.WEB_SOCKET.send(JSON.stringify(getActiveTokens))    },
        // Устанавливаем текущее хранилище
        SET_ACTIVE_TOKEN:(state, token)=>{
            state.commit('SET_ACTIVE_TOKEN', token)        },
        // Подпись json v1 - переименована
        SIGN_JSON:async(state,payload)=>{
            state.commit('SET_ECP_LOADING', true)
            return  new Promise((reslove,reject)=>{
                // Преобразуем в ХМЛ переданный json
                var xml_data = toXML(payload.data)
                // Создаем параметры для NCALayer
                var signXml = {
                    "module": "kz.gov.pki.knca.commonUtils",
                    "method": "signXml",
                    "args": [state.getters.ACTIVE_TOKEN, payload.type, xml_data, "", ""]
                };
                var callback = async function(result){
                    // В случае ошибки возвращаем результат в вызвавшую функцию
                    if(result.code == 500){
                        reject(result)
                        state.commit('SET_ECP_LOADING', false)
                    }else{
                    // В случае успеха сохраняем результат в переменную 
                        state.commit('SET_SIGNED_XML',result.responseObject)
                        state.commit('SET_ECP_LOADING', false)
                        reslove(result.responseObject)
                    }
                }
                state.commit('SET_CALLBACK', callback)     
                state.getters.WEB_SOCKET.send(JSON.stringify(signXml));
            })            
        },
        // Подпись XML
        SIGN_JSON_TO_XML:async(state,payload)=>{
            state.commit('SET_ECP_LOADING', true)
            return  new Promise((reslove,reject)=>{
                // Преобразуем в ХМЛ переданный json
                var xml_data = toXML(payload.data)
                // Создаем параметры для NCALayer
                var signXml = {
                    "module": "kz.gov.pki.knca.commonUtils",
                    "method": "signXml",
                    "args": [state.getters.ACTIVE_TOKEN, payload.type, xml_data, "", ""]
                };
                var callback = async function(result){
                    // В случае ошибки возвращаем результат в вызвавшую функцию
                    if(result.code == 500){
                        reject(result)
                        state.commit('SET_ECP_LOADING', false)
                    }else{
                    // В случае успеха сохраняем результат в переменную 
                        state.commit('SET_SIGNED_XML',result.responseObject)
                        state.commit('SET_ECP_LOADING', false)
                        reslove(result.responseObject)
                    }
                }
                state.commit('SET_CALLBACK', callback)     
                state.getters.WEB_SOCKET.send(JSON.stringify(signXml));
            })            
        },
        // Подпись CMS
        SIGN_TO_CMS:async(state,payload)=>{
            state.commit('SET_ECP_LOADING', true)
            return  new Promise((reslove,reject)=>{
                // Преобразуем в ХМЛ переданный json
                var base64_data = payload.data
                // Если в данных нету флага то отключаем его
                var flag = payload.flags ? payload.flags : false
                // Создаем параметры для NCALayer
                var signCms = {
                    "module": "kz.gov.pki.knca.commonUtils",
                    "method": "createCAdESFromBase64",
                    "args": [state.getters.ACTIVE_TOKEN, payload.type, base64_data, flag]
                };
                var callback = async function(result){
                    // В случае ошибки возвращаем результат в вызвавшую функцию
                    if(result.code == 500){
                        reject(result)
                        state.commit('SET_ECP_LOADING', false)
                    }else{
                    // В случае успеха сохраняем результат в переменную 
                        state.commit('SET_ECP_LOADING', false)
                        reslove(result.responseObject)
                    }
                }
                state.commit('SET_CALLBACK', callback)     
                state.getters.WEB_SOCKET.send(JSON.stringify(signCms));
            })            
        },
        
    },
    
  
    modules: {
    }
  }
  export default {
      ECP: ECP
  }