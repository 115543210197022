<template>
    <v-chip variant="flat" :color="status_colors[status]">{{ title }}</v-chip>
</template>

<script>

   export default {
  name: 'MSubsidyStatus',
  props:{
    status: Number,
    title: String
  },

  data:()=>({

    status_colors:{
        1:'blue-grey-darken-3',
        2:'purple',
        3:'orange-darken-1',
        5: 'red',
        4: 'green'

    }
  }),


}
</script>