<template>
    <v-row no-gutters class="justify-start px-4 py-2" >
      <v-col class="d-flex flex-start align-center"> 
        <v-btn id="btn-add-card" v-if="user_info['org'] != ''" color="green" @click="dialogs.card.show=true">{{this.LOCALIZATION[this.lang].btn_add_card}}</v-btn>
      </v-col>
    </v-row>
    <v-card class="ma-4 mt-0">
      <v-data-table-server
        id="dts-list"
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        v-model:sort-by="sortBy"
        fixed-header
        :header-props='{style:"background-color: #4b5da7;color: #fff;font-weight: 500;"}'
        height = "calc(100vh - 266px)"
        :headers="headers"
        :items="serverItems"
        :items-length="totalItems"
        :loading="loading['page']"
        item-value="id"
        :search="search"
        @update:options="loadItems"
        :items-per-page-text="this.LOCALIZATION[this.lang].rows_on_page_text"
        :loading-text="this.LOCALIZATION[this.lang].loading_title"
      >
      <template v-slot:no-data>{{this.LOCALIZATION[this.lang].no_data_text}}</template>
      <template v-slot:item.actions="{item}">
        <v-btn variant="text" size="small" @click="this.openSubsidyCard(item.id)" icon>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>
      <template v-slot:item.status="{item}">
        <!-- Компонент для отображения статуса -->
        <m-subsidy-status :title = "item.status" :status = "item.status_code"></m-subsidy-status>
      </template>
      </v-data-table-server>
    </v-card>
    <v-dialog v-model="dialogs.card.show" width="800">
      <m-subsidy-card v-model="dialogs.card.id"  @close="closeSubsidyCard()" @save="loadItems()"></m-subsidy-card>
    </v-dialog>
  </template>
  


  
<script>
  import MSubsidyStatus from "./MSubsidyStatus.vue"
  import MSubsidyCard from "./MSubsidyCard.vue"
  import Tour from "tour"


  const LOCALIZATION = {
      RU:{
        loading_title:        'Загрузка...',
        btn_add_card:         'Создать заявку',
        no_data_text:         'Нет данных',
        rows_on_page_text:    'Показывать на странице:',
        tc_fullname:          'ФИО',
        tc_stay_date:         'Дата прибытия',
        tc_decrease_date:     'Дата убытия',
        tc_summ:              'Сумма субсидии',
        tc_org_name:          'Организация',
        tc_status:            'Статус',
        tc_actions:           'Действия',
        hm_next:              'Далее',
        hm_previous:          'Назад',
        hm_finish:            'Завершить',
        hm_steps:{
          0:                  'Кнопка для создания новой заявки',
          1:                  'Здесь отображается список заявок, которые вы подаете в гос. орган.',
          2:                  'по умолчанию Вы авторизовываетесь как Физ.лицо, так как физические лица не могут отпралять заявки вам необходимо, сменить учетную запись на ИП'
      },

      },
      EN:{
        loading_title:        'Loading...',
        btn_add_card:         'Create request',
        no_data_text:         'No data',
        rows_on_page_text:    'Show on page:',
        tc_fullname:          'Full name',
        tc_stay_date:         'Arrival date',
        tc_decrease_date:     'Departure date',
        tc_summ:              'Subsidy amount',
        tc_org_name:          'Organization',
        tc_status:            'Status',
        tc_actions:           'Actions',
        hm_next:              'Next',
        hm_previous:          'Back',
        hm_finish:            'Finish',
        hm_steps:{
            0:                'Button for creating a new application',
            1:                'Here is a list of applications that you submit to the government agency.',
            2:                'by default you are authorized as an Individual, since individuals cannot send applications you need to change your account to an Individual Entrepreneur'
        },
      },
      KZ:{
        loading_title:        'Жүктелуде...',
        btn_add_card:         'Сұраныс жасау',
        no_data_text:         'Дерек жоқ',
        rows_on_page_text:    'Бетте көрсету:',
        tc_fullname:          'Аты',
        tc_stay_date:         'Келу күні',
        tc_decrease_date:     'Шығу күні',
        tc_summ:              'Субсидия сомасы',
        tc_org_name:          'Ұйым',
        tc_status:            'Күй',
        tc_actions:           'Әрекеттер',
        hm_next:              'Келесі',
        hm_previous:          'Артқа',
        hm_finish:            'Аяқтау',
        hm_steps:{
          0:                  'Жаңа сұрау жасау түймесі',
          1:                  'Мұнда сіз мемлекетке жіберетін өтініштер тізімі көрсетіледі. орган.',
          2:                  'Әдепкі бойынша сіз жеке тұлға ретінде кіресіз, өйткені жеке тұлғалар өтініштерді жібере алмайтындықтан, тіркелгіңізді Жеке кәсіпкерге өзгертуіңіз керек'
        },
      },

  }


  export default {
    name: 'MAnimalsFragment',
    components:{
      MSubsidyCard,
      MSubsidyStatus
    },
    data: () => ({
      LOCALIZATION: Object.freeze(LOCALIZATION),
      dialogs:{
        card:{
          show:false,
          id:''
        }
      },
      loading:{
        page: true
      },
      itemsPerPage:10,
      // headers: [],
      serverItems: [],
      editedItem:{},
      page: 1,
      sortBy:[],
      totalItems: 0,
      search:"",
      search_obj:{},
    }),
    computed:{
      lang(){return this.$store.getters.LANG},
      help_messages_status(){return this.$store.getters.HELP_MESSAGES_STATUS},
      
      user_info(){return this.$store.getters.USER_INFO},
      headers(){
        var headers = [
        { title: this.LOCALIZATION[this.lang].tc_fullname, key: 'fullname', align: 'start' },
        { title: this.LOCALIZATION[this.lang].tc_stay_date, key: 'stay_date', align: 'start' },
        { title: this.LOCALIZATION[this.lang].tc_decrease_date, key: 'decrease_date', align: 'start' },
        { title: this.LOCALIZATION[this.lang].tc_summ, key: 'summ' },
        { title: this.LOCALIZATION[this.lang].tc_org_name, key: 'org_name' },
        { title: this.LOCALIZATION[this.lang].tc_status, key: 'status', align:'center' }
      ]
        headers.unshift({ title: this.LOCALIZATION[this.lang].tc_actions, key: 'actions', align: 'start', width:'100px',sortable: false })
      
        return headers
      }
    },
    mounted(){
      if(this.help_messages_status){
        if(this.user_info['org'] != '' && this.$root.help_message_current_status('MSubsidy')==undefined){
          var myTour = {
          canExit: true,
          nextText: LOCALIZATION[this.lang].hm_next,
          previousText: LOCALIZATION[this.lang].hm_previous,
          finishText:LOCALIZATION[this.lang].hm_finish,
          disableInteraction: true,
          steps: [{
          target: '#btn-add-card',
          content: LOCALIZATION[this.lang].hm_steps[0],
          }, {
          target: '#dts-list',
          content: LOCALIZATION[this.lang].hm_steps[1],
          placement: [ 'right', 'top', 'bottom', 'left' ]
          }],
        } 
        Tour.start(myTour).then(result=>{
          this.$store.dispatch('SET_HELP_MESSAGE_STATUS',{name:'MSubsidy', status: true})
        })
      }else if((this.user_info['org'] == '' && this.$store.getters.WORKPLACES.length > 1) && this.$root.help_message_current_status('MSubsidy-fiz') == undefined){
        var myTour = {
          canExit: true,
          nextText: LOCALIZATION[this.lang].hm_next,
          previousText: LOCALIZATION[this.lang].hm_previous,
          finishText:LOCALIZATION[this.lang].hm_finish,
          disableInteraction: true,
          steps: [{
          target: '#btn-edit-workplace',
          content: LOCALIZATION[this.lang].hm_steps[2],
          },
        ],
        } 
        Tour.start(myTour).then(result=>{
          this.$store.dispatch('SET_HELP_MESSAGE_STATUS',{name:'MSubsidy-fiz', status: true})
        })
      }
        
      }
    
    },
    watch:{
      search_obj:{
        deep:true,
        handler(oldVal,newVal){
          this.search = String(Date.now())
        }
      }, 
    },
    methods: {
      
      // Загружаем список субсидий
      loadItems () {
        var page = this.page
        var itemsPerPage = this.itemsPerPage
        this.loading['page'] = true
        var sortBy_obj = {}
        this.sortBy.forEach((element,index )=> {
            sortBy_obj[element.key]  = element.order
        });
        var workplace_id = this.$store.getters.ACTIVE_WORKPLACE
        //Запрос данных с сервера
        this.$store.dispatch('GET_SUBSIDIES',{"page":page, "itemsPerPage":itemsPerPage,"sortBy":sortBy_obj, "search":this.search_obj, "workplace_id":workplace_id}).then(response=>{
          var result = response.data.result
          this.serverItems = result['items']
          this.totalItems = result['total']
          this.loading['page'] = false
        })
      },
      openSubsidyCard(id){
        this.dialogs.card.id = id
        this.dialogs.card.show = true
      },
      closeSubsidyCard(){
        this.dialogs.card.id = ''
        this.dialogs.card.show = false
      }
    }
  }
  
</script>
  
<style>
  .v-data-table-column--align-start{
   text-align: start;
  }
  .v-data-table__thead{
   background-color: rgb(68, 68, 68) !important;
   color: #fff;
   font-weight: 600;
  }
  
  .search-text-filed.v-text-field, 
  .search-text-filed.v-text-field .v-input__control,
  .search-text-filed.v-text-field .v-field__input {
   height: 30px;
   min-height: 30px;
  }
  .search-text-filed.v-text-field .v-field__input{
   padding-top: 0px;
   padding-bottom: 0px;
  }
  
</style>