import { Preferences } from '@capacitor/preferences';
import axios from 'axios';
import { useCookies } from "vue3-cookies";
import sha256 from 'js-sha256';
const { cookies } = useCookies();






const UsersAdmin = {
    state: {
        
    },
    getters: {
        
    },
    mutations: {

    },
    actions: {
        // Получить права доступа
        GET_PERMISSIONS:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/users/admin/v1/permissions",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
            },
        //Добавить право доступа
        ADD_PERMISSION:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/users/admin/v1/permissions", payload)
              .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Изменить права доступа
        EDIT_PERMISSION:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.put(state.getters.SERVER_ADRESS+"/users/admin/v1/permissions", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Удалить право доступа
        DELETE_PERMISSION:(state,id) =>{
            return new Promise((resolve, reject) => {
                axios.delete(state.getters.SERVER_ADRESS+"/users/admin/v1/permissions", {
                    data:{id:id}
                  })
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })
            }, 
        //Получить список групп пользователей
        GET_USER_GROUPS:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/users/admin/v1/user_groups",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
            },
        //Добавить группу пользователей
        ADD_USER_GROUP:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/users/admin/v1/user_groups", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Изменить группу пользователей
        EDIT_USER_GROUP:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.put(state.getters.SERVER_ADRESS+"/users/admin/v1/user_groups", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Удалить группу пользователей
        DELETE_USER_GROUP:(state,id) =>{
            return new Promise((resolve, reject) => {
                axios.delete(state.getters.SERVER_ADRESS+"/users/admin/v1/user_groups", {
                    data:{id:id}
                    })
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Получить список персон
        GET_PERSONS:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/users/admin/v1/persons",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Добавить персону
        ADD_PERSON:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/users/admin/v1/persons", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Изменить персону
        EDIT_PERSON:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.put(state.getters.SERVER_ADRESS+"/users/admin/v1/persons", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
                
            })},
        //Удалить персону
        DELETE_PERSON:(state,id) =>{
            return new Promise((resolve, reject) => {
                axios.delete(state.getters.SERVER_ADRESS+"/users/admin/v1/persons", {
                    data:{id:id}
                    })
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},   
        //Получить список организаций
        GET_ORGS:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/users/admin/v1/orgs",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Добавить организацию
        ADD_ORG:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/users/admin/v1/orgs", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)}); 
            })},
        //Изменить группу пользователей
        EDIT_ORG:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.put(state.getters.SERVER_ADRESS+"/users/admin/v1/orgs", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Удалить группу пользователей
        DELETE_ORG:(state,id) =>{
            return new Promise((resolve, reject) => {
                axios.delete(state.getters.SERVER_ADRESS+"/users/admin/v1/orgs", {
                    data:{id:id}
                    })
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},   

        //Получить список сессий
        GET_SESSIONS:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/users/admin/v1/sessions",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })},
        //Удалить сессию
        DELETE_SESSION:(state,id) =>{
            return new Promise((resolve, reject) => {
                axios.delete(state.getters.SERVER_ADRESS+"/users/admin/v1/sessions", {
                    data:{id:id}
                    })
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
                
            })},
        
        // Получить права доступа объекта
        GET_SUBJECT_PERMISSIONS:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/users/admin/v1/subject_permissions",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })},
        //Добавить право доступа объекту
        ADD_SUBJECT_PERMISSION:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/users/admin/v1/subject_permissions", payload)
              .then(response => {
                    resolve(response);
                },error =>{reject(error)});    
            })},
        //Удалить право доступа
        DELETE_SUBJECT_PERMISSION:(state,id) =>{
            return new Promise((resolve, reject) => {
                axios.delete(state.getters.SERVER_ADRESS+"/users/admin/v1/subject_permissions", {
                    data:{id:id}
                  })
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })}, 

        //Получить список рабочих мест
        GET_WORKPLACES:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/users/admin/v1/workplaces",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Добавить рабочее место
        ADD_WORKPLACE:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/users/admin/v1/workplaces", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Изменить рабочее место
        EDIT_WORKPLACE:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.put(state.getters.SERVER_ADRESS+"/users/admin/v1/workplaces", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Удалить рабочее место
        DELETE_WORKPLACE:(state,id) =>{
            return new Promise((resolve, reject) => {
                axios.delete(state.getters.SERVER_ADRESS+"/users/admin/v1/workplaces", {
                    data:{id:id}
                    })
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })
        },



    },
  
    modules: {
        
    }
}

  

  export default {
    UsersAdmin: UsersAdmin
  }