<template>
    <v-toolbar v-if="top_message" absolute color="rgba(0, 0, 0, 0)" style="z-index: 1005;" height="20px" >
        <v-alert 
        color="rgba(255, 0, 0, 0.6)"
        style="color:#fff"
        height="24px"
        class="px-1 py-0"
        >
        <template v-slot:text>
            <span>{{top_message.text}}</span>
        </template>
        <template v-slot:append>
            <v-btn size="x-small" class="mx-2" height="20px" icon><v-icon>mdi-close</v-icon></v-btn>
        </template>
    </v-alert>
    </v-toolbar>

    <template v-if="snack_message['text']"> 
        <v-snackbar  v-model="snack_show" :timeout="snack_message.timeout" color="green" v-if="snack_message.status == 'success'"> {{ snack_message.text }}</v-snackbar>
        <v-snackbar  v-model="snack_show" :timeout="snack_message.timeout" color="red"  v-if="snack_message.status == 'error'" > {{ snack_message.text }} </v-snackbar>
        <v-snackbar  v-model="snack_show" :timeout="snack_message.timeout" color="orange"  v-if="snack_message.status == 'info'" > {{ snack_message.text }} </v-snackbar>
    </template>  
</template>

<script>
export default {
  name: 'MMessages',
  components:{

  },
  data:()=>({
    snack_show: false,
  }),
  watch:{
    snack_message:{
      deep:true,
      handler(oldVal,newVal){
        this.snack_show = true
      }
    },
  },
  computed:{
    snack_message(){
            return this.$store.getters.SNACK_MESSAGE
        
    },
    top_message(){
            return this.$store.getters.TOP_MESSAGE
        
    }
  },
  methods:{

    
  },
  created(){
   
  }
}

</script>