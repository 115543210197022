import { createStore } from 'vuex'


import IDB from './modules/idb'
import ECP from './modules/ecp'
import Users from './modules/users'
import Messages from './modules/messages'
import HelpMessages from './modules/HelpMessages'
import Help from './modules/help'


 const store = createStore({
  state: {
    
    

  },
  getters: {
      
  },
  mutations: {
    
  },
  actions: {
    
  },
  modules: {
    IDB: IDB.IDB,
    ECP: ECP.ECP,
    Users: Users.Users,
    Messages: Messages.Messages,
    HelpMessages: HelpMessages.HelpMessages,
    Help: Help.Help
  }
})



export default store