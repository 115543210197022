<template>
    <v-row no-gutters v-if="view == 'block'">
        <v-col cols="4" v-for="file,key in files" class="pa-2">
            <v-card>
                <v-card-title class="d-flex justify-end px-1 pt-1">
                    <v-icon size="x-small" style="cursor:pointer" @click="removeFile(key)">mdi-close</v-icon>
                </v-card-title>
                <v-card-text style="height:100px" class="d-flex justify-center align-center">
                    <v-icon 
                        :color = "this.file_icons[file.mime] ? this.file_icons[file.mime].color: '#ccc'" 
                        style="font-size: xx-large;"
                        >
                        {{ this.file_icons[file.mime] ? this.file_icons[file.mime].icon : this.file_icons['other'].icon  }}
                    </v-icon>
                </v-card-text>
                <v-card-actions class="d-block" style="min-height:76px">
                    <span class="truncate text-start">{{ file.name }}</span>
                </v-card-actions>
            </v-card>
            
        </v-col>
    </v-row>
    <v-row no-gutters v-if="view == 'string'">
        <v-col cols="auto" v-for="file,key in files" class="pa-2">
            
                <v-chip label style="cursor:pointer"  @click="downloadFile(key)" >
                    <template v-slot:prepend>
                        <v-icon 
                        class="mx-1"
                            :color = "this.file_icons[file.mime] ? this.file_icons[file.mime].color: '#ccc'"
                        >
                        {{ this.file_icons[file.mime] ? this.file_icons[file.mime].icon : this.file_icons['other'].icon  }}
                        </v-icon>
                    </template>
                    <template v-slot:append>
                        <v-icon v-if="rm" class="mx-1" style="cursor:pointer" @click="removeFile(key)">mdi-close</v-icon>
                    </template>
                    <span class="text-start" style="max-width: 300px;text-overflow: ellipsis; overflow: hidden;">{{ this.trim_file_name(file.name) }}</span>
                    
                </v-chip>
            
        </v-col>
    </v-row>
  </template>

<script>



const isHEX = (ch) => "0123456789abcdef".includes(ch.toLowerCase());
const isGuidValid = (guid) => {
  guid = guid.replaceAll("-", ""); // Format it first!
  return guid.length === 32 && [...guid].every(isHEX);
};

export default {
  name: 'MFilesViewBlock',
  props:{
    modelValue:{
        type:Object,
        default:null
    },
    view:{
        type:String,
        default: 'string'
    },
    rm:{
        type:Boolean,
        default: false
    }
  },
  emits: ['update:modelValue'],
  data:()=>({
    file_icons:{
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':            {icon: 'mdi-file-excel', color:'green'},
        'application/vnd.ms-excel':                                                     {icon: 'mdi-file-excel', color:'green'},
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document':      {icon: 'mdi-file-word',  color:'blue'},
        'application/msword':                                                           {icon: 'mdi-file-word',  color:'blue'},
        'application/pdf':                                                              {icon: 'mdi-file-pdf',   color:'red'},
        'application/pdf':                                                              {icon: 'mdi-file-pdf',   color:'red'},
        'other':                                                                        {icon: 'mdi-file',       color:'blue'},
        
    },
   
  }),
 
  computed:{
    files:{
        get(){
            return this.modelValue
        },
        set(val){
            this.$emit('update:modelValue',val)
        }
    }
  },
  watch:{
    
    
  },
  methods:{
    trim_file_name(str){
        var max_len = 30
        if(max_len < str.length){
            var p = str.lastIndexOf('.')
            if(p < 0){
                p=0
            }
            var format = str.substr(p+1)
            return str.substr(0,max_len-format.length) + '...' + format
        }
        return str


        
    },
    removeFile(id){
        this.files.splice(id, 1);
    },
    async downloadFile(key){
        var data = this.files[key].data
       var isUUID = isGuidValid(data)
       if(isUUID){
        
        await this.$store.dispatch('GET_FILE',{id:data}).then(response=>{
            data = response.data.result
            console.log('sd1')
        })
       }
       console.log('sd')
        const linkSource = `data:`+this.files[key].mime +`;base64,${data}`;
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.href = linkSource;
        downloadLink.target = '_self';
        downloadLink.download = this.files[key].name;
        downloadLink.click();


    }
  },
  created(){

  },
  mounted(){
    
  }
}

</script>

<style scoped>
.truncate {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
           line-clamp: 2;
   -webkit-box-orient: vertical;
}

</style>