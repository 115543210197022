import { Preferences } from '@capacitor/preferences';
import axios from 'axios';
import { useCookies } from "vue3-cookies";
import sha256 from 'js-sha256';
import MSubsidy from '@/fragments/MSubsidy.vue';
const { cookies } = useCookies();






const HelpMessages = {
    state: {
        help_messages_show: {
            status: false,
        },

    },
    getters: {
        HELP_MESSAGES_SHOW: state =>{
            return state.help_messages_show
        },
        HELP_MESSAGES_STATUS: state=>{
            return state.help_messages_show.status
        },
        HELP_MESSAGE_STATUS: state => name =>{
            return state.help_messages_show[name]
        }
    },
    mutations: {
        SET_HELP_MESSAGES_STATUS:(state,status)=>{
            state.help_messages_show.status = status
        },
        SET_HELP_MESSAGE_STATUS:(state,payload)=>{
            state.help_messages_show[payload.name] = payload.status
        },
        SET_HELP_MESSAGES_SHOW:(state,payload)=>{
            state.help_messages_show = payload
        }
    },
    actions: {
        INIT_HELP_MESSAGES: async (state)=>{
            const STATUS =  cookies.get("HELP_MESSAGES_SHOW")
            console.log(STATUS)
            if(STATUS == undefined){
                state.dispatch('SAVE_HELP_MESSAGE_TO_COOKIE')
            }else{
                state.commit('SET_HELP_MESSAGES_SHOW',STATUS)
            }
            
        },
        SAVE_HELP_MESSAGE_TO_COOKIE: async (state)=>{
            cookies.set("HELP_MESSAGES_SHOW",JSON.stringify(state.getters.HELP_MESSAGES_SHOW))
        },

        SET_HELP_MESSAGES_STATUS: async (state, payload)=>{
            state.commit('SET_HELP_MESSAGES_STATUS',payload)
            state.dispatch('SAVE_HELP_MESSAGE_TO_COOKIE')
        },
        SET_HELP_MESSAGE_STATUS: async (state, payload)=>{
            state.commit('SET_HELP_MESSAGE_STATUS',payload)
            state.dispatch('SAVE_HELP_MESSAGE_TO_COOKIE')
        },
        SET_HELP_MESSAGES_SHOW:async (state, payload)=>{
            state.commit('SET_HELP_MESSAGES_SHOW',payload)
            state.dispatch('SAVE_HELP_MESSAGE_TO_COOKIE')
        },
       
    },
  
    modules: {
        
    }
  }

  

  export default {
      HelpMessages: HelpMessages
  }