<template>
  <template v-if="edit == false">
    <v-row no-gutters class="my-1"><v-col><span>{{title}}</span>: <span class="font-weight-bold"> {{ value }}</span></v-col></v-row>
  </template>
  <template v-else>
    <v-row no-gutters class="my-1" v-if="variant == 'text-field'">
      <v-col>
          <span class="font-weight-medium"> 
              <v-text-field  
                  variant="underlined" 
                  class="add_info my-0 py-0" 
                  v-model="data" 
                  autocomplete="off"
                  :maxlength="this.maxlength"
                  :rules="this.rules"
                  :type="this.inputType"
                  :hide-details = "hideDetails"
                  :disabled="this.disabled"
                  @update:focused="(val) =>{this.$emit('update:focused',val)}"
              >
                  <template v-slot:prepend>
                      <span>{{ title }}:</span> 
                  </template>
              </v-text-field>
          </span>
      </v-col>
    </v-row>
    <v-row no-gutters class="my-1" v-if="variant == 'autocomplete'">
      <v-col>
        <v-autocomplete 
                autocomplete="off"
                variant="underlined" 
                v-model="data" 
                class="add_info my-0 py-0 pr-4" 
                :items="this.items"
                :item-title="itemTitle"
                :item-value="itemValue"
                :return-object="false"
                :rules="this.rules"
                :hide-no-data = 'false'
                @update:search="(val) =>{this.$emit('update:search',val)}"
                @update:focused="(val) =>{this.$emit('update:focused',val)}"
                :hide-details = "hideDetails"
                :disabled="this.disabled"
            >
                <template v-slot:prepend>
                    <span>{{ title }}</span> 
                </template>
                <template v-slot:no-data>
                    Нет данных
                </template>
        </v-autocomplete>
        
      </v-col>
    </v-row>
    <v-row no-gutters class="my-1" v-if="variant == 'textarea'">
      <v-col>
        <v-textarea 
            auto-grow 
            rows="1" 
            max-rows="3"
            variant="underlined" 
            class="add_info my-0 py-0" 
            autocomplete="off" 
            :hide-details = "hideDetails"
            v-model="data"
        >
            <template v-slot:prepend>
                <span>{{title}}</span>
            </template>
        </v-textarea>  
      </v-col>
    </v-row>
    <v-row no-gutters class="my-1" v-if="variant == 'date'">
      <v-col>
          <span class="font-weight-medium"> 
              <v-text-field  
                  variant="underlined" 
                  class="add_info my-0 py-0" 
                  v-model="data" 
                  autocomplete="off"
                  :maxlength="this.maxlength"
                  :rules="this.rules"
                  :type="this.inputType"
                  :hide-details = "hideDetails"
                  :disabled="this.disabled"
                  @update:focused="(val) =>{this.$emit('update:focused',val)}"
                  :min="this.minYear == 'all' ? '':this.$root.currentDate().substr(0, 4) + '-01-01'" 
                  :max="this.$root.currentDate()"
              >
                  <template v-slot:prepend>
                      <span>{{ title }}:</span> 
                  </template>
              </v-text-field>
          </span>
      </v-col>
    </v-row>
</template>
</template>

<script>
export default {
    name: 'MHeader',
    emits: ['update:modelValue','update:search','update:focused'],
    props:{
        modelValue: String|Number,
        disabled: Boolean,
        inputType:{
          type: String,
          default: undefined
        },
        value: String,
        title: String,
        edit: {
          type:Boolean,
          default: false
        },
        variant: {
          type:String,
          default: 'text-field'
        },
        maxlength: {
          type:String,
          default: undefined
        },
        rules:{
          type: Array,
          default: undefined
        },
        items:{
          type:Array,
          default: undefined
        },
        itemTitle:String,
        itemValue:String,
        hideDetails:{
          type:Boolean,
          default: true
        },
        minYear:{
          type: String,
          default: 'now'
        }
    },
    data: () => ({

    }),
    computed:{
      data:{
        get(){
          return this.modelValue
        },
        set(val){
          this.$emit("update:modelValue",val)
        }
      }
    }
    
  }

</script>