const IDB = {
    state: {
        ldbs:{}
    },
    getters: {
        LDB: state => key => {
            return state.ldbs[key]
          },
        
    },
    mutations: {
        SET_LDB:(state,ldb) =>{
            state.ldbs[ldb.name]=ldb.db
            
            },
    },
    actions: {
        INIT_LDB:(state,payload={})=>{
            return new Promise((resolve, reject) => {
                if(!('db_name' in payload) || !payload['db_name']) throw "Не задан параметр db_name";
                if(!('store_name' in payload) || !payload['store_name']) throw "Не задан параметр store_name";
                var objectStore, db;
                var indexDB = window.indexedDB.open(payload.db_name, payload.db_version || 1);
                
                // при обновлении
                indexDB.onupgradeneeded = function (event) {
                    db = event.target.result
                    if (!db.objectStoreNames.contains(payload.store_name)) {
                        objectStore = db.createObjectStore (payload.store_name, {keypath: 'id'}) // Define the main key, which is equivalent to ID
                    }
                    }
                
                // при запуске         
                indexDB.onsuccess =  function (event) {
                    
                    db = indexDB.result
                    
                    objectStore = db.transaction(payload.store_name, 'readwrite').objectStore(payload.store_name)
                    let request = objectStore.get(0)
                
                    request.onsuccess =  function (event) {
                        state.commit('SET_LDB', {name:payload.store_name, db: db})
                        resolve(payload.store_name);
                    }
                    }
                    
                })
            },
        PUT_LDB:(state,payload={})=>{
            
            if(!('store_name' in payload) || !payload['store_name']) throw "Не задан параметр store_name";
            if(!('data' in payload) || payload.data.length == 0) throw "Данные для добавления не перееданы";
            if(payload.data.constructor !== Array) throw "Переданные данные не являются массивом";
            const objectStore = state.getters.LDB(payload.store_name).transaction([payload.store_name], 'readwrite').objectStore(payload.store_name)
            payload.data.forEach(item => {
                objectStore.add(item['value'],item['key'])
            });
            
            },
        DELETE_LDB:(state,payload={})=>{
            if(!('store_name' in payload) || !payload['store_name']) throw "Не задан параметр store_name";
            if(!('data' in payload) || payload.data.length == 0) throw "Данные для добавления не перееданы";
            if(payload.data.constructor !== Array) throw "Переданные данные не являются массивом";
            const objectStore = state.getters.LDB(payload.store_name).transaction([payload.store_name], 'readwrite').objectStore(payload.store_name)
            payload.data.forEach(item => {
                objectStore.delete(item['key'])
            });
                
            },
        GET_LDB: async (state, payload={})=>{
            return new Promise((resolve, reject) => {
                let result = ''
                if(!('store_name' in payload) || !payload['store_name']) throw "Не задан параметр store_name";
                if(!('key' in payload)) throw "Ключ не переедан";
                const tx = state.getters.LDB(payload.store_name).transaction([payload.store_name], 'readwrite');
                tx.oncomplete = _ => resolve(result);
                tx.onerror = event => reject(event.target.error);
                const objectStore =  tx.objectStore(payload.store_name)
                var request = objectStore.get(payload.key)
                
                request.onsuccess = ()=> {
                    result = request.result;
                    
                }
            })
        }   
     },
    
  
    modules: {
    }
  }
  export default {
      IDB: IDB
  }