<template>
    <v-card>
        <v-card-title>
            {{LOCALIZATION[this.lang].confirm_label}}
        </v-card-title>
        <v-card-text v-if="!this.loading">
            <span v-if="this.variant == 'approve'">{{LOCALIZATION[this.lang].appove_text}}</span>
            <span v-if="this.variant == 'refuse'">{{ LOCALIZATION[this.lang].refuse_text}}</span>
            <v-textarea v-if="this.variant == 'refuse'"  v-model="json_data['Content']['Note']" :placeholder="LOCALIZATION[this.lang].textarea_title"></v-textarea>
        </v-card-text>
        <v-card-text v-else>
            {{LOCALIZATION[this.lang].loading_title}}
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
            <v-btn variant="text" :disabled="this.loading" style="color:green" @click="sign()">{{LOCALIZATION[this.lang].button_sign_and_send}}</v-btn>
            <v-btn variant="text" :disabled="this.loading" style="color:green" @click="this.$emit('close')">{{LOCALIZATION[this.lang].button_close}}</v-btn>
        </v-card-actions>
    </v-card>
    <m-nuc-sign  v-model="sign_dialog" @sign="send($event)" variant="cms" type="SIGNATURE" :data="this.sign_data" :lang="this.lang"></m-nuc-sign> 
    
</template>
<script>
import MNucSign from "@miril/m-nuc-sign";
import { Base64 } from 'js-base64';


const LOCALIZATION = {
  RU:{
    confirm_label:              'Подтверждение',
    appove_text:                'Вы подтверждаете принятие данных, отправленных туроператором?',
    refuse_text:                'Вы подтверждаете отказ от принятия данных, отправленных туроператором?',
    textarea_title:             'Причина отказа',
    loading_title:              'Загрузка...',
    button_sign_and_send:       'Подписать и отправить',
    button_close:               'Закрыть'
  },
  EN:{
    confirm_label:              'Confirmation',
    appove_text:                'Do you confirm acceptance of the data sent by the tour operator?',
    refuse_text:                'Do you confirm refusal to accept the data sent by the tour operator?',
    textarea_title:             'Reason for refusal',
    loading_title:              'Loading...',
    button_sign_and_send:       'Sign and send',
    button_close:               'Close'
  },
  KZ:{
    confirm_label:              'Растау',
    appove_text:                'Туроператор жіберген деректердің қабылданғанын растайсыз ба?',
    refuse_text:                'Туроператор жіберген деректерді қабылдаудан бас тартуыңызды растайсыз ба?',
    textarea_title:             'Бас тарту себебі',
    loading_title:              'Жүктелуде...',
    button_sign_and_send:       'Қол қою және жіберу',
    button_close:               'Жабу'
  },
}


export default {
    name: 'MApproveSubsidy',
    components:{
       MNucSign
    },
    emits: ['update:modelValue','close','save'],
    props:{
        modelValue: String|Boolean,
        variant: {
            type: String,
            default: 'approve'
        }
    },
    data:()=>({
        LOCALIZATION: Object.freeze(LOCALIZATION),
        sign_data: '',
        sign_dialog: false,
        json_data: {
            'User':{},
            'Content': {}
        },
        loading: false
    }),
    computed:{
        lang(){return this.$store.getters.LANG}
    },
    mounted(){
        var obj = this.getUserInfo()
        this.json_data['User'] = JSON.parse(JSON.stringify(obj))
    },
    methods:{
        async send(s_data){
            this.loading = true
            if(this.variant == 'approve'){
                this.$store.dispatch('APPROVE_SUBSIDY',{id:this.modelValue, json_data: this.json_data, signature: s_data}).then(response=>{
                    
                    this.$emit('save')
                    this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":response.data.message,'status':'success'})
                    this.loading = false
                },error=>{
                    this.loading = false
                this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":error.response.data.message,'status':'error'})
            })
            }else{
                this.$store.dispatch('REFUSE_SUBSIDY',{id:this.modelValue, json_data: this.json_data, signature: s_data}).then(response=>{
                    this.$emit('save')
                    this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":response.data.message,'status':'success'})
                    this.loading = false
                },error=>{
                    this.loading = false
                    this.$store.dispatch("SEND_SNACK_MESSAGE",{"text":error.response.data.message,'status':'error'})
                })
            }
            

        },
        async sign(){
                this.sign_data = this.toBase64(this.json_data)
                this.sign_dialog = true
           
            
        },
         //Получаем информацию о пользователе
         getUserInfo(){
            var obj ={}
            var user_info = this.$store.getters.USER_INFO
            obj['OrgBin'] = user_info['org']['bin']
            obj['OrgName'] = user_info['org']['name']
            obj['UserIin'] = user_info['person']['iin']
            obj['UserName'] = user_info['person']['lastname'] +' '+ user_info['person']['firstname'] + ' '+ user_info['person']['patronymic']
            return obj
        },
        //Конвертировать данные в base64
        toBase64(data){

            var n_data = {}
            var keys = Object.keys(data).sort()

            for(var k_index in keys){
                var elem = Object.keys(data[keys[k_index]]).sort()
                
                var sdata = elem.reduce(
                (obj, key) => {
                    obj[key] = data[keys[k_index]][key];
                    return obj;
                },{})
                n_data[keys[k_index]] = sdata
            }
                console.log(n_data)
                var res = Base64.encode(JSON.stringify(n_data))
        
                return res
        },
    }
}
</script>