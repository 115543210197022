
import axios from 'axios';

var cancleTokens = {}

const Help = {
    state: {
        loading_progress:{}
    },
    getters: {
      LOADING_PROGRESS: (state) => (id) =>{
        return state.loading_progress[id]
      },
      LOADING_PROGRESS_ALL: (state)=>{
        return state.loading_progress
      }
    },
    mutations: {
        SET_LOADING_PROGRESS: (state,payload) =>{
            if(payload.value == undefined){
                delete state.loading_progress[payload.id]
            }else{
                state.loading_progress[payload.id] = payload.value
            }
            
            
        },
    },
    actions: {
        GET_HELPS_LIST:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/help/v1/helps_list",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
            },
        //
        GET_PEOPLES_LIST:(state, payload) =>{
            if(cancleTokens['get_peoples']){
                cancleTokens['get_peoples'].cancel();
             }
             const CancelToken = axios.CancelToken;
             cancleTokens['get_peoples'] = CancelToken.source();
             console.log(cancleTokens['get_peoples'])
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/help/v1/peoples",{
                    cancelToken: cancleTokens['get_peoples'].token,
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{
                    reject(error)                    
                });
                })
            },
        //
        ADD_PEOPLE:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/help/v1/peoples", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Изменить группу пользователей
        EDIT_PEOPLE:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.put(state.getters.SERVER_ADRESS+"/help/v1/peoples", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Удалить группу пользователей
        DELETE_PEOPLE:(state,id) =>{
            return new Promise((resolve, reject) => {
                axios.delete(state.getters.SERVER_ADRESS+"/help/v1/peoples", {
                    data:{id:id}
                    })
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
       
        //hhh
        GET_AFFECTED_HOUSES:(state, payload) =>{
            if(cancleTokens['get_affected_houses']){
                cancleTokens['get_affected_houses'].cancel();
             }
             const CancelToken = axios.CancelToken;
             cancleTokens['get_affected_houses'] = CancelToken.source();
             
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/help/v1/affected_houses",{
                    cancelToken:  cancleTokens['get_affected_houses'].token,
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
            },
        //
        ADD_AFFECTED_HOUSE:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/help/v1/affected_houses", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Изменить группу пользователей
        EDIT_AFFECTED_HOUSE:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.put(state.getters.SERVER_ADRESS+"/help/v1/affected_houses", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        //Удалить группу пользователей
        DELETE_AFFECTED_HOUSE:(state,id) =>{
            return new Promise((resolve, reject) => {
                axios.delete(state.getters.SERVER_ADRESS+"/help/v1/affected_houses", {
                    data:{id:id}
                    })
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        ADD_HELPS:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/help/v1/helps", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        GET_HELPS_REPORT:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/help/v1/report",{
                    responseType: 'blob',
                    params:payload
                }).then(response => {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');

                
                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = today.getFullYear();
                    today = dd + '-' + mm + '-' + yyyy;

                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'Отчет '+ today +'.xlsx');
                        document.body.appendChild(fileLink);

                        fileLink.click();
                },error =>{reject(error)});
                })
        },
        GET_HELP_ORGS:(state, payload) =>{
            if(cancleTokens['get_help_orgs']){
                cancleTokens['get_help_orgs'].cancel();
             }
             const CancelToken = axios.CancelToken;
             cancleTokens['get_help_orgs'] = CancelToken.source();
             
            return new Promise((resolve, reject) =>  {
                axios.get(state.getters.SERVER_ADRESS+"/help/v1/helps/orgs",{
                    cancelToken:  cancleTokens['get_help_orgs'].token,
                    responseType: "json"
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
                })
        },
        ADD_SUBSIDY:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/help/v2/add", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
                
            })},
        ADD_ORG_DOC:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/sub/v1/org/docs", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        GET_ORG_DOCS:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.get(state.getters.SERVER_ADRESS+"/sub/v1/org/docs",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        GET_FILE:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.get(state.getters.SERVER_ADRESS+"/sub/v1/files",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    
                    resolve(response);
                },error =>{reject(error)});
            })},
        ADD_SUBSIDY_V1:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/sub/v1/subsidies", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        SIGN_SUBSIDY:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.put(state.getters.SERVER_ADRESS+"/sub/v1/subsidies/sign", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        EDIT_SUBSIDY:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.put(state.getters.SERVER_ADRESS+"/sub/v1/subsidies", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        GET_SUBSIDIES:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.get(state.getters.SERVER_ADRESS+"/sub/v1/subsidies",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        DELETE_SUBSIDY:(state,id) =>{
            return new Promise((resolve, reject) => {
                axios.delete(state.getters.SERVER_ADRESS+"/sub/v1/subsidies", {
                    data:{id:id}
                    })
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        CHECK_ORG_DOC_SIGN:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.get(state.getters.SERVER_ADRESS+"/sub/v1/org/docs/sign",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        TO_WORK_SUBSIDY:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/sub/v1/subsidies/to_work", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        APPROVE_SUBSIDY:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/sub/v1/subsidies/approve", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        REFUSE_SUBSIDY:(state,payload) =>{
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/sub/v1/subsidies/refuse", payload)
                .then(response => {
                    resolve(response);
                },error =>{reject(error)});
            })},
        UPLOAD_FILES: (state,payload) =>{
            var uuid = payload['id']
            delete payload['id']
            return new Promise((resolve, reject) => {
                axios.post(state.getters.SERVER_ADRESS+"/sub/v1/files", payload,{
                    onUploadProgress: function(progressEvent) {
                        state.commit('SET_LOADING_PROGRESS',{id: uuid, value: parseInt(Math.round(( progressEvent.loaded / progressEvent.total) * 100))})
                    }.bind(this)})
                .then(response => {
                    resolve(response);
                },error =>{reject(error)}).finally(()=>{
                    state.commit('SET_LOADING_PROGRESS',{id:uuid, value: undefined})
                });
            })},
          

    },
       
    modules: {
    }
  }
  export default {
      Help: Help
  }