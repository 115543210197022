import { Preferences } from '@capacitor/preferences';
import axios from 'axios';
import { useCookies } from "vue3-cookies";
import sha256 from 'js-sha256';
const { cookies } = useCookies();
import Authorization from "@/store/modules/users/auth.js"
import Initialization from "@/store/modules/users/init.js"
import UsersAdmin from "@/store/modules/users/admin"





const Users = {
    state: {
        reload: false,
        server:{
            host:'localhost',
            port:'8888',
            proto:'http',
            connection_status:'',
        },
        refresh_access_token_url: 'http://localhost:8888/users/v1/access-token',
    },
    getters: {
        RELOAD_STATUS: state =>{
            return state.reload
        },
        SERVER_ADRESS: state =>{
           var address =  state.server.proto + '://'+ state.server.host 
           if(state.server.port ) address += ':' + state.server.port 
            return address   },

        REFRESH_ACCESS_TOKEN_URL: state =>{
            return state.refresh_access_token_url
        }
    },
    mutations: {
        RELOAD_STATUS: (state,status) =>{
            state.reload = status
        },
        SET_SERVER:(state, server) =>{
            state.server = server
        },
        SET_REFRESH_ACCESS_TOKEN_URL:(state,url)=>{
            var address = state.server.proto + '://'+ state.server.host 
            if(state.server.port) address += ':' + state.server.port 
            state.refresh_access_token_url = address + url
        }
        
        

        },
    actions: {
        SET_REFRESH_ACCESS_TOKEN_URL:(state,url)=>{
            return new Promise((resolve, reject) =>  {
                // Отправляем запрос на авторизацию пользователя
                state.commit('SET_REFRESH_ACCESS_TOKEN_URL',url)
                resolve(true)

            })
        },
        SET_SERVER:(state, payload)=>{
            return new Promise((resolve, reject) =>  {
                // Отправляем запрос на авторизацию пользователя
                state.commit('SET_SERVER',payload)
                resolve(true)

            })
        },
        RELOAD_STATUS:(state,payload) =>{
            state.commit('RELOAD_STATUS', payload)
        },
        INIT_USERS: async (state, payload) =>{
            let refreshLoading = false;
                let refreshSubscribers=[]
                function wait() {
                    var i = 0;
                    return new Promise(function (resolve) {
                        var timerId = setInterval(()=>{
                            if(!refreshLoading){
                                console.log('hhhhh')
                                
                                clearInterval(timerId)
                                resolve(true)    
                            }
                    
                
                        },1000)
                        
                    });
                }

            axios.interceptors.request.use(async function (config) {
                // Добавляем ко всем запросам Access Token если он существует
                var accessToken = cookies.get('accessToken')
                var workplace_id = state.getters.ACTIVE_WORKPLACE
                
                if(workplace_id != ''){
                    config.headers['WorkplaceId'] = workplace_id
                }

                if(config.url == state.getters.REFRESH_ACCESS_TOKEN_URL){
                    
                    refreshLoading = true
                    
                }
                
                config.headers['Lang'] = state.getters.LANG
                
                if(accessToken != undefined){
                    config.headers["Authorization"] = 'Bearer ' + accessToken;
                }
                return config;
                
                }, function (error) {
                
                return Promise.reject(error);
            });  
            // Устанавливаем пост обработку запросов
            axios.interceptors.response.use((response)=> {  
                // if(state.getters.TOP_MESSAGE){
                //     state.dispatch('CLEAR_TOP_MESSAGE')
                // }
                return response;
                },  async (error)=> {
                    if(axios.isCancel(error)){
                        return Promise.reject('Cancled');
                    }
                
                const response = error.response
                // console.log(error)
                // if(error.code == "ERR_NETWORK"){
                //     state.dispatch('SEND_TOP_MESSAGE',{text:'Отсутсвтует подключение к серверу'})
                //     state.dispatch('SEND_SNACK_MESSAGE',{text:'Нет подключения', status:"error"})
                //     // function sleep(milliseconds) {
                //     //     const date = Date.now();
                //     //     let currentDate = null;
                //     //     do {
                //     //       currentDate = Date.now();
                //     //     } while (currentDate - date < milliseconds);
                //     //   }
                //     // sleep(2000);
                //     // return axios.request(error.config)
                //     return Promise.reject('CONNECTION');
                // }
                // Если Access Token недействительный
                if(response.status == '405'){
                    const config = error.config
                    console.log(config)
                    // Добавляем запросы в очередь
                    refreshSubscribers.push(config)
            
                    var index = refreshSubscribers.indexOf(config)
                    if(refreshSubscribers.length == 1){
                        
                        const ret = await Preferences.get({ key: 'refreshToken' });
                        
                        const refreshToken = JSON.parse(ret['value']);
                        
                        await axios.post(state.getters.REFRESH_ACCESS_TOKEN_URL, {
                            'refresh_token': refreshToken
                        }).then(async response => {
                            console.log(response)
                            var result = response.data.result
                            await Preferences.set({
                                key: 'refreshToken',
                                value: JSON.stringify(result['refreshToken'])})
                                cookies.set("accessToken",result['accessToken'])
                                refreshLoading = false
                        },async error =>{
                            await state.dispatch('LOGOUT_USER')
                            return Promise.reject('Unauthorized');
                            })
                        refreshSubscribers = []
                    }else{
                        console.log(index)
                        await wait()
                    }
                    
                    var accessToken = cookies.get('accessToken')
                    if(accessToken != undefined){
                        config.headers["Authorization"] = 'Bearer ' + accessToken;
                    }
                    console.log(index)
                    // return axios.request(config)
            
                      
                    // await state.dispatch('GET_NEW_ACCESS_TOKEN', refresh_token).then(response=>{
                        
                    // }, error=>{
                    // })
                        
                    // // })
                    // console.log('11111')
                    return axios.request(config)
                }else if(response.status == '401'){
                    console.log('Пользователь не авторизован')
                    
                    await state.dispatch('LOGOUT_USER')
                    
                    
                    
                    
                    return Promise.reject('Unauthorized');
                }
            
                var message = {text:error.response.data.message, status: 'error'}
                state.commit("SET_SNACK_MESSAGE", message)
                return Promise.reject(error);
            });
            return new Promise(async (resolve, reject) =>  {
                console.log('RT')
                await state.dispatch('SET_REFRESH_TOKEN')
                console.log('AT')
                await state.dispatch('SET_ACCESS_TOKEN')
                console.log('AW')
                await state.dispatch('SET_ACTIVE_WORKPLACE')
                // Получаем Refresh Token из localStorage
                //Устанавливаем пред обработку запросов к серверу
                // Если пользователь авторизован
                if(state.getters.AUTH_STATUS == true){
                    // Получаем список его рабочих мест
                    await state.dispatch('SET_WORKPLACES').then(response=>{
                        console.log(response)
                    })
                    
                    // Если рабочее место не установлено его нужно установить
                    if(state.getters.ACTIVE_WORKPLACE == null){
                        //Если одно устанавливаем автоматически
                        
                        var workplaces = state.getters.WORKPLACES
                        
                        if(workplaces.length>1){
                            var fiz = workplaces.find(elem =>{
                                return elem.org_name == null
                            })
                            await state.dispatch('SET_ACTIVE_WORKPLACE', fiz.id)
                        }else{
                            await state.dispatch('SET_ACTIVE_WORKPLACE', workplaces[0].id)
                        }
                        
                        
                        
                            
                    }
                    await state.dispatch('GET_USER_BASE_INFO').then(response=>{
                        console.log(response)
                    },error=>{
                      console.log(error)
                      var result = error.response.data.result
                      if(result['activation'] == false){
                        console.log('Требуется активация')
                      }
                    })
                    resolve('TRUE')  
                }else{
                    resolve('TRUE')
                }
            })
            
            

        },
        
        SET_USERS_SESSIONS:(state, payload) =>{
            return new Promise((resolve, reject) =>  {
                // Отправляем запрос на авторизацию пользователя
                axios.get(state.getters.SERVER_ADRESS+"/users/admin/v1/sessions",{
                    responseType: "json",
                    params:payload
                }).then(response => {
                    var result = response.data.result
                    // Оборачиваем данные в "data" и сохраняем
                    
                    resolve(response);
                },error =>{
                    reject(error)
                 });
              
                })
        },
        
    },
  
    modules: {
        Authorization: Authorization.Authorization,
        Initialization: Initialization.Initialization,
        UsersAdmin: UsersAdmin.UsersAdmin
    }
  }

  

  export default {
      Users: Users
  }